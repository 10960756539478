import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {URL} from '../paths/url'
// import Cookies from 'js-cookie'

const initialState = {
    courseModalOpen: false,
    coursePopOverOpen: false,
    deleteCourseModalOpen: false,
    selectedCourseElem: "",
    delCourseName: '',
    courses: [],
    isLoading: false,
    createCourseLoad: false,
    isError: false,
    errorMessage: '',
    fillCourseInputs: {
      course_title: '', 
      course_description: '', 
      course_videoUrl: '', 
      course_price: ''
    },


    // edit 
    isEditing: false,
    editCourseLoad: false,
    editCourseError: false,
    editCourseModalOpen: false,
    editCourseErrorMsg: "",
    courseEditId: "",
    courseEditImageUrl: "",

    // delete 
    courseDeleteId: "",
    deleteCourseLoad: false,
    deleteCourseError: false,
    deleteCourseErrorMsg: "",

    // read more
    readMoreActive: false,
    courseReadId: []

}

export const getCourses = createAsyncThunk(
    'actions/getCourses',
    async (payload, thunkAPI) => {
      try {
        const resp = await axios.get(
          `${URL}/api/v1/course`, 
          {
            withCredentials: true,
          }
        )
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const createCourse = createAsyncThunk(
    'actions/createCourse',
    async (payload, thunkAPI) => {
      const {course_title, course_description, course_imageUrl, course_videoUrl, course_price} = payload
      try {
        const resp = await axios.post(
          `${URL}/api/v1/course`, 
          {
            course_title, course_description, course_imageUrl, course_videoUrl, course_price
          },
          {
            withCredentials: true, 
          }
        )
        thunkAPI.dispatch(getCourses())
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const editCourse = createAsyncThunk(
    'actions/editCourse',
    async (payload, thunkAPI) => {
      const {course_title, course_description, course_imageUrl, course_videoUrl, course_price, id} = payload
      try {
        const resp = await axios.patch(
          `${URL}/api/v1/course/${id}`, 
          {
            course_title, course_description, course_imageUrl, course_videoUrl, course_price
          },
          {
            withCredentials: true, 
          }
        )
        thunkAPI.dispatch(getCourses())
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const deleteCourse = createAsyncThunk(
    'actions/deleteCourse',
    async (payload, thunkAPI) => {
      const {id} = payload
      try {
        const resp = await axios.delete(
          `${URL}/api/v1/course/${id}`, 
          {
            withCredentials: true, 
          }
        )
        thunkAPI.dispatch(closeDeleteCourseModal())
        thunkAPI.dispatch(getCourses())
        return { response: resp.data, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

const authSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        toggleCourseModal: (state, action) => {
            state.courseModalOpen = !state.courseModalOpen
        },
        toggleCoursePopOver: (state, action) => {
            state.selectedCourseElem = action.payload;
            state.coursePopOverOpen = !state.coursePopOverOpen
        },
        closeCoursePopOver: (state, action) => {
          state.selectedCourseElem = action.payload
          state.coursePopOverOpen = false
        },
        toggleDeleteCourseModal: (state, action) => {
          const {course_title, _id} = action.payload
            state.delCourseName = course_title
            state.courseDeleteId = _id
            state.deleteCourseModalOpen = !state.deleteCourseModalOpen
        },
        closeDeleteCourseModal: (state, action) => {
          state.deleteCourseModalOpen = false
          state.delCourseName = ""
          state.courseDeleteId = ""
        },
        toggleEditCourseModal: (state, action) => {
          state.editCourseModalOpen = !state.editCourseModalOpen
          state.isEditing = !state.isEditing
        },
        closeEditCourseModal: (state, action) => {
          state.editCourseModalOpen = false
          state.courseEditId = ""
          state.courseEditImageUrl = ""
          state.fillCourseInputs = {
            course_title: '', 
            course_description: '', 
            course_videoUrl: '', 
            course_price: ''
          }
          state.isEditing = false
        },
        fillCourseInputs: (state, action) => {
          const {
              course_title, 
              course_description, 
              course_videoUrl, 
              course_price
          } = action.payload

          state.fillCourseInputs.course_title = course_title
          state.fillCourseInputs.course_description = course_description
          state.fillCourseInputs.course_videoUrl = course_videoUrl
          state.fillCourseInputs.course_price = course_price
        },
        fillEditInputs : (state, action) => {
          const {
            course_title, 
            course_description, 
            course_videoUrl, 
            course_price,
            course_imageUrl,
            _id
        } = action.payload
        state.fillCourseInputs.course_title = course_title
          state.fillCourseInputs.course_description = course_description
          state.fillCourseInputs.course_videoUrl = course_videoUrl
          state.fillCourseInputs.course_price = course_price
          state.courseEditId = _id
          state.courseEditImageUrl = course_imageUrl
        }, 
        toggleReadMore: (state, action) => {
          const id = action.payload
          state.courseReadId = id
          state.readMoreActive = !state.readMoreActive

        }
    },
    extraReducers(builder) {
      // get courses 
        builder.addCase(getCourses.pending, (state, action) => {
            state.isLoading = true
            state.isError = false
        })
        .addCase(getCourses.fulfilled, (state, action) => {
            state.isLoading = true
            const {response, status} = action.payload
            if (status === 'success') {
                state.isLoading = false
                state.courses = response.courses
            } else {
                // state.isLoading = true
                state.isError = true
                state.errorMessage = response.msg
            }
        })
        .addCase(getCourses.rejected, (state, action) => {
            state.isLoading = true
            state.isError = true
            state.errorMessage = 'Something went wrong'
        })
        // create course 
        .addCase(createCourse.pending, (state, action) => {
          state.createCourseLoad = true
          state.isError = false
      })
      .addCase(createCourse.fulfilled, (state, action) => {
          state.createCourseLoad = true
          const {response, status} = action.payload
          if (status === 'success') {
              state.createCourseLoad = false
              state.courses = response.courses
              state.courseModalOpen = false
              state.fillCourseInputs = {
                course_title: '', 
                course_description: '', 
                course_videoUrl: '', 
                course_price: ''
              }

          } else {
              state.createCourseLoad = false
              state.isError = true
              state.errorMessage = response.msg              
          }
      })
      .addCase(createCourse.rejected, (state, action) => {
          state.createCourseLoad = false
          state.isError = true
          state.errorMessage = 'Something went wrong'
      })

      // update course 

      .addCase(editCourse.pending, (state, action) => {
        state.editCourseLoad = true
        state.editCourseError = false
    })
    .addCase(editCourse.fulfilled, (state, action) => {
        state.editCourseLoad = true
        const {response, status} = action.payload
        if (status === 'success') {
            state.editCourseLoad = false
            state.isEditing = false
            state.editCourseModalOpen = false
            state.fillCourseInputs = {
              course_title: '', 
              course_description: '', 
              course_videoUrl: '', 
              course_price: ''
            }
            
        } else {
            state.editCourseLoad = false
            state.editCourseError = true
            state.editCourseErrorMsg = response.msg
            
        }
    })
    .addCase(editCourse.rejected, (state, action) => {
        state.editCourseLoad = false
        state.editCourseError = true
        state.editCourseErrorMsg = 'Something went wrong'
    })
 
    // delete course 
    .addCase(deleteCourse.pending, (state, action) => {
      state.deleteCourseLoad = true
      state.deleteCourseError = false
    })
    .addCase(deleteCourse.fulfilled, (state, action) => {
        state.deleteCourseLoad = true
        const {response, status} = action.payload
        if (status === 'success') {
            state.deleteCourseLoad = false
            state.deleteCourseError = false
            state.courseDeleteId = ""
            state.delCourseName = ""

        } else {
            state.deleteCourseLoad = false
            state.deleteCourseError = true
            state.deleteCourseErrorMsg = response.msg
            
        }
    })
    .addCase(deleteCourse.rejected, (state, action) => {
        state.deleteCourseLoad = false
        state.deleteCourseError = true
        state.deleteCourseErrorMsg = 'Something went wrong'
    })

    }
})

export default authSlice.reducer
export const {
  toggleCourseModal,
  toggleCoursePopOver,
  closeCoursePopOver,
  toggleDeleteCourseModal,
  toggleEditCourseModal,
  fillCourseInputs,
  fillEditInputs,
  closeDeleteCourseModal,
  toggleReadMore
} = authSlice.actions